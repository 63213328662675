<template>
	<div>
		<!-- Header -->
		<b-container class="h-100vh d-flex mt-7 justify-content-center">
			<div>
				<b-row class="justify-content-center">
					<img
						src="../../../../client/src/assets/Logo/ComodeIcon.svg"
						class="logo"
						style="cursor: pointer"
						@click="$router.push('/adminApp/login')"
					/>
				</b-row>
				<div class="d-flex justify-content-center screen-title">
					ユーザー登録が完了しました。
				</div>
				<b-row class="justify-content-center">
					<b-col>
						<card class="w-100 my-0 text-center">
							<div class="elm-title">
								登録確認メールをご指定のメールアドレスに送信しました。
							</div>
							<div class="elm-title mt-2">
								メールが届いていない場合、迷惑メール防止機能により迷惑メールと間違えられている場合があります。
							</div>
							<router-link to="/adminApp/login" class="text-light"
								><div class="direct-link-underline mt-5">
									ログインはこちら
								</div></router-link
							>
						</card>
					</b-col>
				</b-row>
			</div>
		</b-container>
	</div>
</template>

<script>
export default {
	name: 'login',
};
</script>
<style scoped>
.logo {
	height: 5vh;
	width: 15vw;
	max-width: 182px;
}

.back-ground {
	background-color: whitesmoke;
}

.text {
	margin-top: 10px;
	color: #4d4d4d;
}

.text-font {
	font-family: NotoSansJPBold;
}

.typography {
	font-family: NotoSansJPBold;
	color: #4d4d4d;
	text-align: center;
}

.direct-link {
	margin: 50px 0px 0px !important;
	color: blue;
	text-decoration: underline;
	opacity: 0.8;
}

.direct-link:hover {
	opacity: 1;
}

p {
	margin: 0px 5px !important;
}

@font-face {
	font-family: NotoSansJPBold;
	src: url('../../../../client/src/assets/fonts/A-OTF-MidashiGoPro-MB31.otf')
		format('opentype');
}
</style>
